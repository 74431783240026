<template>
  <div>
    <ul class="list-group mb-3">
      <li
        v-for="item in items"
        :key="item.id"
        class="list-group-item lh-condensed"
        id="sticky-header"
      >
        <b-row>
          <b-col class="d-flex flex-column align-items-start" md="7">
            <h6 class="my-0">
              {{ item.description }}
            </h6>
            <small class="text-muted">{{
              get_product_parcel(item.sequence)
            }}</small>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-end justify-content-center"
          >
            <span class="text-muted"> {{ toMonetary(item.value) }}</span>
          </b-col>
        </b-row>
      </li>
      <li
        v-show="!sticky"
        class="list-group-item d-flex justify-content-between"
      >
        <span>Total</span>
        <strong>{{ toMonetary(invoice[0].total) }}</strong>
      </li>
      <li
        v-if="sticky"
        class="list-group-item d-flex justify-content-between sticked"
      >
        <span>Total</span>
        <strong>{{ toMonetary(invoice[0].total) }}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["items", "invoice"],
  data() {
    return {
      sticky: false,
    };
  },
  methods: {
    get_product_parcel(item) {
      if (item) {
        const [actual, total] = item.split("/");
        return `Parcela ${actual} de ${total}`;
      } else {
        return "";
      }
    },
  },
  mounted() {
    const self = this;
    window.onscroll = function () {
      myFunction();
    };

    function myFunction() {
      var header = document.getElementById("sticky-header");
      var sticky = header.getBoundingClientRect();

      if (sticky.bottom < 0) {
        self.sticky = true;
      } else {
        self.sticky = false;
      }
    }
  },
};
</script>

<style scoped>
.sticked {
  position: fixed;
  top: 0;
  left: 0;
  margin-right: 1rem;
  background-color: white;
  width: 100%;
}
</style>